* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.corpo {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  position: relative;
  background-color: #0d2b1d;
  /* Cor sólida de fundo base */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Substitui background-size para imagens */
  filter: blur(8px);
  /* Efeito de desfoque */
  opacity: 0.5;
  /* Opacidade padrão */
  z-index: 1;
  transition: opacity 0.6s ease-in-out;
  /* Transição suave para o fade */
}

.background.fade {
  opacity: 0;
  /* Torna o fundo invisível durante o fade */
  animation: fade 0.6s ease-in-out;
  /* Animação de fade */
}

@keyframes fade {
  0% {
    opacity: 0.5;
  }

  /* Começa na opacidade padrão */
  50% {
    opacity: 0;
  }

  /* Meio da transição */
  100% {
    opacity: 0.5;
  }

  /* Volta à opacidade padrão */
}

.scontainer {
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.57);
  /* Ajustado para rgba */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  position: relative;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 1));
  transition: transform 0.6s ease-in-out;
  /* Transição suave */
}

.avatar.spin {
  animation: spin 0.6s ease-in-out;
  /* Animação de rotação */
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  font-size: 18px;
  color: white;
  background-color: #13361c;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
}

.link:hover {
  background-color: #2f6d32;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  /* Leve elevação ao passar o mouse */
}

.links:hover> :not(:hover) {
  filter: blur(2px);
  opacity: 0.8;
  transition: filter 0.3s, opacity 0.3s;
}